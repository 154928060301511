import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useScheduledComments from "~/hooks/useScheduledComments";
import CobuTableAdvanced from "~/components/CobuTableAdvanced/CobuTableAdvanced";
import { CellProps } from "react-table";
import moment from "moment";
import Spacer from "~/components/Spacer/Spacer";
import Breadcrumbs from "~/components/Breadcrumbs/Breadcrumbs";
import GenericModal from "~/components/GenericModal/GenericModal";
import ScheduleCommentModal from "~/components/CreateScheduledComment/CreateScheduledComment";
import { CreateCommentBody } from "~/types/scheduledComment";
import { getBuildingFromStore } from "~/helpers/reduxStoreHelpers";
import styles from '../ActiveSurveys/ActiveSurvey.module.css'
import Spinner from "~/components/Spinner/Spinner";
import MoreActions from "~/components/MoreActions/MoreActions";
import CobuConstants from "~/helpers/cobuConstants";

const ScheduledComments = () => {

    const { buildingId } = useParams<{ buildingId: string }>();
    const { scheduledComments, groups, createScheduledComment, deleteScheduledComment, editScheduledComment, loadingConfig } = useScheduledComments(buildingId);
    const [showTooltipCreateModal, setShowTooltipCreateModal] = useState<boolean>(false)
    const [editComment, setEditComment] = useState<any>(null)
    const selectedBuilding = getBuildingFromStore(buildingId);


    // Table Configuration
    const scheduledCommentsTableConfig = [
        {
            Header: "Building",
            accessor: "building.name",
            Cell: (cell: CellProps<any>) => (
                <div>{cell.row.original.building?.name || "Unknown"}</div>
            ),
        },
        {
            Header: "Group",
            accessor: "group.name",
            Cell: (cell: CellProps<any>) => (
                <div>{cell.row.original.group?.name || "Building Chat"}</div>
            ),
        },
        {
            Header: "User",
            accessor: "user.firstName",
            Cell: (cell: CellProps<any>) => (
                <div>{cell.row.original.user?.firstName || "Unknown"}</div>
            ),
        },
        {
            Header: "Comment",
            accessor: "contents",
            Cell: (cell: CellProps<any>) => (
                <div style={{ whiteSpace: "normal" }}>
                    {cell.row.original.contents}
                </div>
            ),
        },
        {
            Header: "Post Time",
            accessor: "postTime",
            Cell: (cell: CellProps<any>) => (
                <div>
                    {moment(cell.row.original.postTime).tz(cell.row.original.building?.timezone)
                        .format('MM/DD/YYYY hh:mma')}
                </div>
            ),
        },
        {
            Header: 'IMAGE',
            Cell: (cell: CellProps<any>) => {
                return (
                    cell.row.original.images.length > 0 ?
                        <a
                            href={cell.row.original.images[0].image}
                            target="_blank"
                            className='default-text'>
                            View Image
                        </a>
                        :
                        <div>--</div>

                )
            }
        },
        {
            Header: "Created At",
            accessor: "createdAt",
            Cell: (cell: CellProps<any>) => (
                <div>
                    {moment(cell.row.original.createdAt)
                        .local()
                        .format("MM/DD/YYYY")}
                </div>
            ),

        },
        {
            id: 'more',
            Cell: (cell: CellProps<any>) => (
                <MoreActions
                    title={CobuConstants.residentManagement.actions}
                    options={[
                        {
                            label: '       DELETE         ',
                            action: () => {
                                deleteScheduledComment(cell.row.original.uuid);
                            }
                        },
                        // {
                        //     label: '       EDIT         ',
                        //     action: () => {
                        //         setEditComment(cell.row.original)
                        //         setShowTooltipCreateModal(!showTooltipCreateModal)
                        //     }
                        // },
                    ]}
                />
            )
        }
    ];

    const createAction = () => {
        return (
            <button
                className='btn-primary btn-standard bold'
                onClick={() => {
                    setShowTooltipCreateModal(!showTooltipCreateModal)
                }}
            >
                Create Scheduled Comment
            </button>
        );
    };

    const createModal = () => {
        return showTooltipCreateModal
            ?
            <GenericModal
                showModal={showTooltipCreateModal}
                title={'Create Scheduled Comment'}
                enableOverflowScrolls
                content={
                    <ScheduleCommentModal
                        selectedBuilding={selectedBuilding}
                        editComment={editComment}
                        existingImage={(editComment && editComment.images.length > 0) ? editComment.images[0].image : ''}
                        cancel={function (): void {
                            setShowTooltipCreateModal(!showTooltipCreateModal);
                        }}
                        createComment={function (comment: CreateCommentBody): void {
                            createScheduledComment({ ...comment, building: buildingId, timezone: selectedBuilding.timezone });
                            setShowTooltipCreateModal(!showTooltipCreateModal);
                        }}
                        groups={groups}
                        editcomment={function (comment: CreateCommentBody): void {
                            editScheduledComment({ ...comment, building: buildingId, timezone: selectedBuilding.timezone, uuid : editComment.uuid })
                            setShowTooltipCreateModal(!showTooltipCreateModal);
                        }}
                    />
                }
                hideActionButton
                canceActionButton
                actionText={'Create'}
                actionFunction={() => { }}
                onChange={() => { }}
            /> : null
    }

    if (loadingConfig.flag) return <div className={styles.loadingContainer}>
        <Spinner />
        <h3 style={{ textAlign: 'center' }}>{loadingConfig.comment}</h3>
    </div>

    return (
        <div className="content-container">
            <Breadcrumbs />
            <Spacer height={20} />
            <h1>Scheduled Comments</h1>
            <Spacer height={20} />
            <CobuTableAdvanced
                columns={scheduledCommentsTableConfig}
                data={scheduledComments}
                noData={{
                    label: "No scheduled comments available.",
                    action: undefined,
                    image: undefined,
                }}
                actions={[createAction()]}
                actionsStyle={{ top: '130px' }}
            />

            {createModal()}
        </div>
    );
};

export default ScheduledComments;