import moment from 'moment';
import React, { useState } from 'react';
import { CellProps } from 'react-table';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import CobuTableAdvanced from '~/components/CobuTableAdvanced/CobuTableAdvanced';
import Spacer from '~/components/Spacer/Spacer';
import Spinner from '~/components/Spinner/Spinner';
import CobuConstants from '~/helpers/cobuConstants';
import styles from './ActiveSurvey.module.css';
import GenericModal from '~/components/GenericModal/GenericModal';
import {
  getCreateSurveyFormConfig,
  getCreateTooltipFormConfig
} from '~/helpers/formHelpers';
import CreateTooltipForm from '~/components/CreateSurveyForm/CreateTooltipForm';
import useTooltips from '~/hooks/surveys/useTooltips';
import { Tooltip } from '~/types/tooltip';

const ActiveTooltips = () => {
  const {
    activeTooltips,
    loadingConfig,
    deactivateTooltip,
    createTooltip,
    updateTooltip
  } = useTooltips();
  const [showTooltipCreateModal, setShowTooltipCreateModal] =
    useState<boolean>(false);
  const [tooltipToEdit, setTooltipToEdit] = useState<Tooltip | null>(null);

  /* UI Components */

  const activeTooltipTableConfig = [
    {
      Header: 'BUILDING',
      accessor: 'building.name'
    },
    {
      Header: 'TITLE',
      accessor: 'title',
      Cell: (cell: CellProps<Tooltip>) => {
        return (
          <div style={{ whiteSpace: 'normal' }} className='default-text'>
            {cell.row.original.title}
          </div>
        );
      }
    },
    {
      Header: 'DESCRIPTION',
      accessor: 'description',
      Cell: (cell: CellProps<Tooltip>) => {
        return (
          <div style={{ whiteSpace: 'normal' }} className='default-text'>
            {cell.row.original.description}
          </div>
        );
      }
    },
    {
      Header: 'LINK URL',
      Cell: (cell: CellProps<Tooltip>) => {
        return (
          <a
            href={cell.row.original.linkURL}
            target='_blank'
            className='default-text'
          >
            {cell.row.original.linkType === 'External'
              ? 'View External Link'
              : cell.row.original.linkURL}
          </a>
        );
      }
    },
    {
      Header: 'CALL TO ACTION',
      accessor: 'callToAction'
    },
    {
      Header: 'TYPE',
      accessor: 'linkType',
      Cell: (cell: CellProps<Tooltip>) => {
        return (
          <div style={{ whiteSpace: 'normal' }} className='default-text'>
            {cell.row.original?.linkType}
          </div>
        );
      }
    },
    {
      Header: 'CREATED',
      accessor: 'createdAt',
      Cell: (cell: CellProps<Tooltip>) => (
        <div>
          {moment(cell.row.original.createdAt).local().format('MM/DD/YYYY')}
        </div>
      )
    },
    {
      id: 'deactivate',
      Cell: (cell: CellProps<Tooltip>) => (
        <div
          className={'more-actions-btn more-actions-btn-unselected'}
          onClick={() => {
            deactivateTooltip(cell.row.original);
          }}
        >
          {CobuConstants.activeTooltips.deactivateSurvey}
        </div>
      )
    },
    {
      id: 'editTooltip',
      Cell: (cell: CellProps<Tooltip>) => (
        <div
          className={'more-actions-btn more-actions-btn-unselected'}
          onClick={() => {
            setTooltipToEdit(cell.row.original);
            setShowTooltipCreateModal(true);
          }}
        >
          {CobuConstants.activeTooltips.editToolTip}
        </div>
      )
    }
  ];

  const getCobuTableInstance = (data: Tooltip[], placeholder: string) => {
    return (
      <CobuTableAdvanced
        columns={activeTooltipTableConfig}
        data={data}
        noData={{
          label: placeholder,
          action: undefined,
          image: undefined
        }}
        actions={[createTooltipAction()]}
        actionsStyle={{ top: '130px' }}
      />
    );
  };

  const createTooltipAction = () => {
    return (
      <button
        className='btn-primary btn-standard bold'
        onClick={() => {
          setShowTooltipCreateModal(!showTooltipCreateModal);
        }}
      >
        {CobuConstants.activeTooltips.createTooltip}
      </button>
    );
  };

  if (loadingConfig.flag)
    return (
      <div className={styles.loadingContainer}>
        <Spinner />
        <h3 style={{ textAlign: 'center' }}>{loadingConfig.comment}</h3>
      </div>
    );

  const createTooltipModal = () => {
    return showTooltipCreateModal ? (
      <GenericModal
        showModal={showTooltipCreateModal}
        title={CobuConstants.activeTooltips.createTooltip}
        content={
          <CreateTooltipForm
            cancel={() => {
              setShowTooltipCreateModal(!showTooltipCreateModal);
            }}
            createTooltip={(tooltip) => {
              setShowTooltipCreateModal(!showTooltipCreateModal);
              createTooltip(tooltip);
            }}
            updateToolTip={(tooltip) => {
              setShowTooltipCreateModal(!showTooltipCreateModal);
              updateTooltip(tooltip);
            }}
            formConfig={getCreateTooltipFormConfig()}
            defaultData={tooltipToEdit}
          />
        }
        hideActionButton
        canceActionButton
        actionText={CobuConstants.residentManagement.createActionTitle}
        actionFunction={() => {}}
        onChange={() => {}}
      />
    ) : null;
  };

  return (
    <div className='content-container'>
      <Breadcrumbs />
      <Spacer height={20} />
      <h1>{CobuConstants.activeTooltips.title}</h1>
      <Spacer height={20} />
      {getCobuTableInstance(
        activeTooltips,
        CobuConstants.activeTooltips.noActiveSurveys
      )}
      {createTooltipModal()}
    </div>
  );
};

export default ActiveTooltips;
