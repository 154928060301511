import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { getBuildingConfig, updateBuildingConfig } from '~/api/building';
import { toast } from 'react-toastify';

const ROOT_URL = 'your-api-root-url-here'; // Replace with your API root URL
const bugsnagClient = { notify: console.error }; // Replace with your Bugsnag client if applicable

// Define the structure of building configuration
interface BuildingConfig {
  uuid: string;
  config?: Record<string, { hidePerks: boolean; showGoogleReview: boolean }>;
}

// Hook return type
interface UseBuildingMobileConfigReturn {
  loadingMobileSettings: boolean;
  buildingConfig: BuildingConfig | null;
  hidePerks: boolean;
  setHidePerks: React.Dispatch<React.SetStateAction<boolean>>;
  showGoogleReview: boolean;
  setShowGoogleReview: React.Dispatch<React.SetStateAction<boolean>>;
  fetchBuildingConfig: (id: string) => Promise<void>;
  updateBuildingConfigs: (updateData: UpdateConfigParams) => Promise<void>;
}

// Update configuration parameters
interface UpdateConfigParams {
  hidePerks: boolean;
  showGoogleReview: boolean;
}

const useBuildingMobileConfig = (buildingId: string): UseBuildingMobileConfigReturn => {
  const [loadingMobileSettings, setLoading] = useState<boolean>(true);
  const [buildingConfig, setBuildingConfig] = useState<BuildingConfig | null>(null);
  const [hidePerks, setHidePerks] = useState<boolean>(false);
  const [showGoogleReview, setShowGoogleReview] = useState<boolean>(false);

  const cookies = new Cookies();
  const token = cookies.get('token');

  // Fetch building configuration
  const fetchBuildingConfig = async (id: string) => {
    setLoading(true);
    try {
      const { data } = await getBuildingConfig(buildingId)
      setBuildingConfig(data);
      if (data.config) {
        const configValues = Object.values(data.config)[0];
        //@ts-ignore
        setHidePerks(configValues?.hidePerks);
        //@ts-ignore
        setShowGoogleReview(configValues.showGoogleReview);
      }
    } catch (error) {

    } finally {
      setLoading(false);
    }
  };

  // Update building configuration
  const updateBuildingConfigs = async ({ hidePerks, showGoogleReview }: UpdateConfigParams) => {
    let data = { config: { hidePerks, showGoogleReview, building: buildingId } };

    if (buildingConfig?.config) {
      const configJson = Object.values(buildingConfig.config)[0];
      configJson['hidePerks'] = hidePerks;
      configJson['showGoogleReview'] = showGoogleReview;

      //@ts-ignore
      data = { config: buildingConfig.config };
    }

    try {
      await updateBuildingConfig(buildingConfig?.uuid ?? '', data)
      // Refresh the building config after update
      toast('App config has been successfully changed!');
      fetchBuildingConfig(buildingId);
    } catch (error) {
      toast(
        'There was an error while updating the building config. Please try again later.'
      );
    }
  };

  // Fetch config on initial render
  useEffect(() => {
    if (buildingId) {
      fetchBuildingConfig(buildingId);
    }
  }, [buildingId]);

  return {
    loadingMobileSettings,
    buildingConfig,
    hidePerks,
    setHidePerks,
    showGoogleReview,
    setShowGoogleReview,
    fetchBuildingConfig,
    updateBuildingConfigs,
  };
};

export default useBuildingMobileConfig;