import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { CellProps } from 'react-table';
import { toast } from 'react-toastify';
import { getNotificationTypes, getNotifications } from '~/api/notification';
import {
  getUserReferralLink,
  patchIntercomUserReferralDetails
} from '~/api/referral';
import {
  getResidentProfile,
  removeProfilePic,
  updateNotifcationSettings,
  updatePassword,
  updateResidentProfile
} from '~/api/resident';
import Checkbox from '~/components/CheckBox/CheckBox';
import CobuConstants from '~/helpers/cobuConstants';
import residentProfile, {
  hideLoader,
  initialState,
  loader,
  setProfileData,
  toggleUserEditModel
} from '~/reducers/residentProfile';
import { CreateResidentBodyType } from '~/types/residentManagement';
import {
  NotificationTypes,
  RSVPUIModel,
  ResidentProfileData
} from '~/types/residentProfile';

export default (residentId: string, buildingId: string) => {
  const [loading, setLoading] = useState(false);
  const [referralLink, setReferralLink] = useState<string | null>(null);
  const [residentProfileState, dispatch] = useReducer(
    residentProfile,
    initialState
  );

  useEffect(() => {
    fetchResidentProfile();
    fetchUserReferralLink();
  }, []);

  const fetchUserReferralLink = async () => {
    try {
      const { data } = await getUserReferralLink(buildingId, residentId);

      setReferralLink(data);
    } catch (e) {
      toast('Failed to fetch user referral link');
    }
  };

  /*Data Logics*/
  const fetchResidentProfile = async (refresh = false) => {
    try {
      dispatch(
        loader({
          loadingString: refresh
            ? CobuConstants.residentProfile.refreshData
            : CobuConstants.empty
        })
      );
      const [
        residentProfileResponse,
        notificationTypeResponse,
        userNotificationsResponse
      ] = await Promise.all([
        getResidentProfile(residentId),
        getNotificationTypes(),
        getNotifications(residentId)
      ]);
      dispatch(
        setProfileData({
          residentData: residentProfileResponse.data,
          rsvpData: computeRsvpData(residentProfileResponse.data),
          notificationData: computeNotificationSettings(
            residentProfileResponse.data.disabledPushNotificationTypes ?? [],
            notificationTypeResponse.data
          ),
          notifications: userNotificationsResponse.data
        })
      );
    } catch (error) {
      dispatch(hideLoader());
      if (error) {
        toast(error?.message);
      }
    }
  };

  const computeRsvpData = (
    residentProfile: ResidentProfileData
  ): RSVPUIModel[] => {
    const feedbackUuids = new Set(
      residentProfile.groupEventFeedback.map((feedback) => feedback.groupEvent)
    );
    const rsvpModels = residentProfile.rsvps.map((rsvp) => ({
      title: rsvp.groupEvent.title,
      rating: feedbackUuids.has(rsvp.groupEvent.uuid)
        ? residentProfile.groupEventFeedback.find(
            (item) => item.groupEvent === rsvp.groupEvent.uuid
          )?.generalRating || '-'
        : '-',
      comments:
        residentProfile.groupEventComments
          .filter((item) => item.groupEvent === rsvp.groupEvent.uuid)
          .map((item) => item.contents)
          .join(',') || '-',
      createdAt: rsvp.groupEvent.createdAt
    }));
    //@ts-ignore
    return rsvpModels.reverse();
  };

  const computeNotificationSettings = (
    userNotificationSettings: string[],
    notificationTypes: NotificationTypes[]
  ): NotificationTypes[] => {
    return notificationTypes.map((notificationType) => {
      return {
        ...notificationType,
        isActiveForResident: !userNotificationSettings.includes(
          notificationType.uuid
        )
      };
    });
  };

  const updateResidentData = async (resident: CreateResidentBodyType) => {
    try {
      dispatch(
        loader({
          loadingString: CobuConstants.residentProfile.updateResidentDataLoader
        })
      );
      const residentDataUpdate = await updateResidentProfile(resident);
      if (resident.password?.trim()) {
        const passwordUpdate = await updatePassword(
          resident.id,
          resident.password.trim()
        );
      }
      toast(CobuConstants.residentProfile.updateResidentDataSuccess);
      showHideUserEditModal();
      refreshData();
    } catch (error) {
      dispatch(hideLoader());
      if (error) {
        toast(error?.message);
      }
    }
  };

  const deleteProfilePic = async () => {
    try {
      dispatch(
        loader({
          loadingString: CobuConstants.residentProfile.removeProfilePicLoader
        })
      );
      const profilepicRemoveResp = await removeProfilePic(residentId);
      toast(CobuConstants.residentProfile.removeProfilePicSuccess);
      showHideUserEditModal();
      refreshData();
    } catch (error) {
      dispatch(hideLoader());
      if (error) {
        toast(error?.message);
      }
    }
  };

  const updateNotificationSetting = async (uuids: string[]) => {
    try {
      dispatch(
        loader({
          loadingString:
            CobuConstants.residentProfile.updateNotificationSettingLoader
        })
      );
      const updateNotifcationSettingResp = await updateNotifcationSettings(
        residentId,
        uuids
      );
      toast(CobuConstants.residentProfile.updateNotificationSettingSuccess);
      refreshData();
    } catch (error) {
      dispatch(hideLoader());
      if (error) {
        toast(error?.message);
      }
    }
  };

  /*UI Logics*/

  const refreshData = () => {
    fetchResidentProfile(true);
  };

  const showHideUserEditModal = () => {
    dispatch(toggleUserEditModel());
  };

  const commentTableConfig = [
    {
      Header: 'DATE',
      accessor: 'createdAt',
      Cell: (cell: CellProps<any>) => (
        <div>
          {moment(cell.row.original.createdAt).local().format('MM/DD/YYYY')}
        </div>
      )
    },
    {
      Header: 'COMMENT',
      accessor: 'contents',
      Cell: (cell: CellProps<any>) => {
        return (
          <div style={{ whiteSpace: 'normal' }} className='default-text'>
            {cell.row.original.contents}
          </div>
        );
      }
    }
  ];

  const rsvpTableConfig = [
    {
      Header: 'RSVPS',
      Cell: (cell: CellProps<RSVPUIModel>) => {
        return (
          <div style={{ whiteSpace: 'normal' }} className='default-text'>
            {`${moment(cell.row.original.createdAt)
              .local()
              .format('MM/DD/YYYY')}: ${cell.row.original.title}`}
          </div>
        );
      }
    },
    {
      Header: 'EVENT RATINGS',
      accessor: 'rating'
    },
    {
      Header: 'COMMENTS',
      accessor: 'comments'
    }
  ];

  //dummy

  const notifTableConfig = [
    {
      Header: 'NOTIFICATIONS',
      accessor: 'content',
      Cell: (cell: CellProps<any>) => {
        return (
          <div style={{ whiteSpace: 'normal' }} className='default-text'>
            {cell.row.original.content}
          </div>
        );
      }
    },
    {
      Header: 'READ',
      id: 'check',
      Cell: (cell: CellProps<any>) => {
        const option = {
          text: CobuConstants.empty,
          value: cell.row.original.uuid
        };
        let selectedOption = cell.row.original.isRead
          ? cell.row.original.uuid
          : '';
        return (
          <Checkbox
            index={cell.row.index}
            defaultSetting
            option={option}
            selectedOption={selectedOption}
            setSelectedOption={function (value: string): void {}}
          />
        );
      }
    },
    {
      Header: 'DATE',
      accessor: 'createdAt',
      Cell: (cell: CellProps<any>) => (
        <div>
          {moment(cell.row.original.createdAt)
            .local()
            .format('MM/DD/YYYY hh:mm A')}
        </div>
      )
    }
  ];

  const syncIntercomUserReferralDetails = async () => {
    try {
      setLoading(true);
      await patchIntercomUserReferralDetails(residentId);

      setLoading(false);

      toast('Intercom Referral details synced successfully!');
    } catch (e) {
      setLoading(false);
    }
  };

  return {
    residentProfileState,

    updateResidentData,
    deleteProfilePic,
    updateNotificationSetting,
    showHideUserEditModal,

    commentTableConfig,
    rsvpTableConfig,
    notifTableConfig,
    syncIntercomUserReferralDetails,
    loading,
    referralLink
  };
};
