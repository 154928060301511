import React, { FC, ReactNode } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  RegisterOptions,
  Ref
} from 'react-hook-form';
import { FormInputConfig } from '~/types/residentManagement';
import ImageDropzone from '../ImageDropzone/ImageDropzone';
import { CobuFormErrors } from '~/helpers/cobuConstants';
import Switch from 'react-ios-switch';
import { ISelectOption } from '~/helpers/interfaces';
import MultiSelect from '../MultiSelect/MultiSelect';
import { SelectType } from '~/enums/SelectType';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromHTML, convertToRaw, ContentState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface IProps {
  fieldConfig: FormInputConfig;
  errors?: FieldErrors<any>;
  control?: Control<any>;
  register?: (rules?: RegisterOptions) => (ref: Ref | null) => void;
  namePreFix?: string | number;
  nameIndex?: string | number;
  defaultValue?: string | number;
  disabled?: boolean;
}

export const FormField: FC<IProps> = ({
  errors,
  fieldConfig,
  register,
  control,
  namePreFix,
  nameIndex,
  defaultValue,
  disabled
}) => {
  let error = errors?.[`${fieldConfig.id}`];
  const formFieldName =
    namePreFix && (nameIndex as number) >= 0
      ? `${namePreFix}[${nameIndex}].${fieldConfig.id}`
      : `${namePreFix ? `${namePreFix}.` : ''}${fieldConfig.id}`;
  const isRequiredField = fieldConfig.isDisabled
    ? false
    : 'isRequired' in fieldConfig
    ? fieldConfig.isRequired
    : true;

  const styleProps = {
    style: {
      ...(fieldConfig?.styles?.inputStyles || {}),
      ...(fieldConfig.displayNone ? { display: 'none' } : {})
    },
    className: `${fieldConfig.readOnly && 'nonEditable'} ${
      error && 'border-red'
    } ${fieldConfig?.styles?.inputClassName}`
  };

  const rules = {
    required: isRequiredField,
    pattern: fieldConfig.pattern,
    maxLength: fieldConfig.maxLength,
    minLength: fieldConfig.minLength,
    validate: fieldConfig.validations
  };

  const commonProps = {
    ...styleProps,
    ...(register
      ? {
          ref: register(rules)
        }
      : {}),
    name: formFieldName,
    defaultValue,
    ...(fieldConfig.inputTypeProps ?? {})
  };

  const customBlockParser = (blockContent: string, blocks: any) => {
    if (blockContent === '<br>') {
      return [{ type: 'unstyled' }]; // Convert <br> into unstyled block
    }
    return blocks;
  };

  let inputComponent: ReactNode;

  switch (fieldConfig.inputType) {
    case 'text':
    case 'date':
      inputComponent = (
        <input
          {...commonProps}
          placeholder={fieldConfig.placeholder}
          type={fieldConfig.inputType || 'text'}
          readOnly={fieldConfig.readOnly}
        />
      );
      break;
    case 'select':
      inputComponent = (
        <select disabled={disabled} {...commonProps}>
          <option value='' disabled selected>
            Select your option
          </option>
          {fieldConfig.selectOptions?.map((option) => (
            <option
              key={option.value}
              label={option.label}
              value={option.value}
            >
              {option.value}
            </option>
          ))}
        </select>
      );
      break;
    case 'multi-select':
      inputComponent = (
        <Controller
          name={formFieldName}
          defaultValue={defaultValue}
          rules={{ required: fieldConfig.isRequired }}
          render={({ onChange, value }) => (
            <MultiSelect
              options={fieldConfig.selectOptions as ISelectOption[]}
              selectType={SelectType.AutoAdjutForm}
              multiValue={value}
              setMultiValue={onChange}
              placeholder='Select buildings...'
              cobuPlusBuildings={[]}
              defaultOptions={[]}
            />
          )}
          control={control}
        />
      );
      break;
    case 'textarea':
      inputComponent = (
        <Controller
          name={formFieldName}
          defaultValue={defaultValue}
          rules={rules}
          control={control}
          render={({ value, onChange }) => (
            <textarea
              defaultValue={defaultValue}
              value={value}
              onChange={onChange}
              cols={30}
              rows={6}
              placeholder={fieldConfig.placeholder}
              {...styleProps}
              {...(fieldConfig.inputTypeProps ?? {})}
            />
          )}
        />
      );
      break;
    case 'imageDropzone':
      inputComponent = (
        <Controller
          name={formFieldName}
          defaultValue={defaultValue}
          rules={{ required: fieldConfig.isRequired }}
          render={({ onChange, value }) => (
            // @ts-ignore
            <ImageDropzone
              image={value}
              acceptedFiles={fieldConfig.acceptedFiles}
              handleDrop={(file) => {
                onChange(file[0]);
              }}
            />
          )}
          control={control}
        />
      );
      break;
    case 'switch':
      inputComponent = (
        <Controller
          name={formFieldName}
          defaultValue={defaultValue}
          rules={{ required: fieldConfig.isRequired }}
          render={({ onChange, value }) => (
            <Switch
              checked={value}
              onChange={(currentSwitchState: boolean) => {
                onChange(currentSwitchState);
              }}
              disabled={false}
            />
          )}
          control={control}
        />
      );
      break;
    case 'richEditor':
      inputComponent = (
        <Controller
          name={formFieldName}
          defaultValue={defaultValue}
          rules={{ required: fieldConfig.isRequired }}
          render={({ onChange, value }) => {
            const blocksFromHTML = convertFromHTML(value, undefined);

            const contentState = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks, // contentBlocks from convertFromHTML
              blocksFromHTML.entityMap // entityMap from convertFromHTML
            );

            const raw = convertToRaw(contentState);

            return (
              <Editor
                editorStyle={{
                  height: fieldConfig.inputTypeProps.height || '400px',
                  width:
                    fieldConfig.inputTypeProps.width ||
                    window.innerWidth * 0.35,
                  border: '1px solid black'
                }}
                initialContentState={raw}
                toolbar={{
                  options: ['inline', 'blockType', 'emoji', 'list', 'link'],
                  inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough'] // Inline style options (bold, italic, etc.)
                  },
                  blockType: {
                    inDropdown: true,
                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined
                  }
                }}
                toolbarClassName='toolbarClassName'
                wrapperClassName='wrapperClassName'
                editorClassName='editorClassName'
                onEditorStateChange={(newEditorState) => {
                  const contentState = newEditorState.getCurrentContent();

                  const plainText = contentState.getPlainText();
                  const htmlContent = convertToHTML({
                    blockToHTML: (block) => {
                      if (
                        (block.type === 'unstyled' ||
                          block.type.includes('header')) &&
                        !block.text
                      ) {
                        return <div>&shy;</div>;
                      }
                    },
                    entityToHTML: (entity, originalText) => {
                      if (entity.type === 'LINK') {
                        return {
                          start: `<a href="${entity.data.url}">`,
                          end: `</a>`
                        };
                      }

                      return originalText;
                    }
                  })(contentState);

                  onChange(
                    fieldConfig.inputTypeProps.isPlainText
                      ? plainText
                      : htmlContent
                  );
                }}
              />
            );
          }}
          control={control}
        />
      );
      break;
    case 'custom':
      inputComponent = fieldConfig.customComponent;
      break;
    default:
      inputComponent = (
        <input
          {...commonProps}
          placeholder={fieldConfig.placeholder}
          type={fieldConfig.inputType || 'text'}
          readOnly={fieldConfig.readOnly}
        />
      );
      break;
  }

  const content = fieldConfig.sibling ? (
    //Shivam: Do not remvoe empty space, inputComponent stops working
    <div style={fieldConfig.styles?.contentStyles}>
      {' '}
      {inputComponent}
      {fieldConfig.sibling}
    </div>
  ) : fieldConfig.styles?.contentStyles ? (
    <div style={fieldConfig.styles?.contentStyles}> {inputComponent}</div>
  ) : (
    inputComponent
  );

  return (
    <>
      {content}
      {error && error.type === 'required' && (
        <span
          className='errorTitle'
          role='alert'
          style={fieldConfig?.styles?.errorStyles}
        >
          {CobuFormErrors.requiredError}
        </span>
      )}
      {error && fieldConfig.pattern && error.type === 'pattern' && (
        <span
          className='errorTitle'
          role='alert'
          style={fieldConfig?.styles?.errorStyles}
        >
          {fieldConfig.patternErrorMsg}
        </span>
      )}
      {error && fieldConfig.maxLengthError && error.type === 'maxLength' && (
        <span
          className='errorTitle'
          role='alert'
          style={fieldConfig?.styles?.errorStyles}
        >
          {fieldConfig.maxLengthError}
        </span>
      )}
      {error && fieldConfig.maxLengthError && error.type === 'minLength' && (
        <span
          className='errorTitle'
          role='alert'
          style={fieldConfig?.styles?.errorStyles}
        >
          {fieldConfig.minLengthError}
        </span>
      )}
      {error && error.message && error.type === 'validate' && (
        <span
          className='errorTitle'
          role='alert'
          style={fieldConfig?.styles?.errorStyles}
        >
          {error.message}
        </span>
      )}
    </>
  );
};
