import { convertKeyValueToFormDataV2 } from "~/helpers/helpers";
import { emperor } from "~/services";



export const getBuildingImages = async (building: string) => {
    return await emperor.get(`building/images`, { params: { building } });
};

export const deleteBuildingImage = async (building: string, image: String) => {
    return await emperor.delete(`building/image`, { params: { building, image } });
};

export const addImageToBuilding = async (imageObj: Object) => {
    const data = convertKeyValueToFormDataV2(imageObj);
    return await emperor.post(`building/image`, data);
};