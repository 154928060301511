import React from 'react';
import { CellProps, Column } from 'react-table';
import './GatheringsTable.css';
import moment, { duration } from 'moment';
import { GatheringType } from '~/types/gathering';
import buttonStyle from './GatheringTable.styles';
import CobuTable from '../CobuTable/CobuTable';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ReactComponent as Community } from '~/utils/images/community.svg';
import MoreActionsGatherings from '../MoreActionsGatherings/MoreActionsGatherings';
import { Gathering } from '~/enums/Gathering';
import { ReactComponent as StarOff } from '~/utils/images/starOff.svg';
import { ReactComponent as StarOn } from '~/utils/images/starOn.svg';
import TextDelimeter from '../TextDelimeter/TextDelimeter';
import styles from './GatheringsTable.module.css';
import { Colors } from '~/enums/Colors';
import _ from 'lodash';
import CobuTableAdvanced from '../CobuTableAdvanced/CobuTableAdvanced';
import { sortEvents } from './utils';

const LiveFlag = () => (
  <p
    style={{
      padding: '4px 8px',
      backgroundColor: 'green',
      fontSize: '12px',
      borderRadius: '3px',
      color: 'white',
      fontWeight: 500,
      letterSpacing: 0.8
    }}
    className='blink'
    title='Live'
  >
    HAPPENING NOW
  </p>
);

const getDisplayDatetime = (
  datetime: string,
  timezone: string,
  format: string
) => {
  if (!datetime) return '--:--';

  return moment(datetime).tz(timezone).format(format);
};

const convertTimeFromUtcToBuildingTimeZone = (
  datetime: string,
  timezone: string
) => {
  if (!datetime) return '--:--';

  return moment.utc(datetime).tz(timezone).format();
};

const getDisplayDatetimeWithDefaultDateFormat = (
  datetime: string,
  timezone: string
) => {
  if (!datetime) return '--:--';

  return moment(datetime).tz(timezone).format('ddd, DD MMM YYYY');
};

export const getEventDuration = (startTime: string, endTime: string) => {
  if (!endTime) return '--:--';

  const duration = moment.duration(moment(endTime).diff(startTime));

  const days = Math.floor(duration.asDays());

  return `${
    days > 0 ? `${days}d ` : ''
  }${duration.hours()}hr ${duration.minutes()}min`;
};

export const formatAverageRating = (averageRating: number) => {
  if (!averageRating || averageRating == 0) {
    return 'n/a';
  } else {
    return averageRating;
  }
};

interface IProps {
  data: GatheringType[];
  gatheringType: number;
  getGatherings: () => void;
}

const GatheringsTable = (props: IProps) => {
  const { buildingId } = useParams<{ buildingId: string }>();
  const history = useHistory();

  const { data, gatheringType } = props;

  const { recurringEvents, nonRecurringEvents } = React.useMemo(() => {
    let recurringEvents: GatheringType[] = [],
      nonRecurringEvents: GatheringType[] = [];

    data.forEach((event) => {
      if (event.isRecurring) {
        recurringEvents.push(event);
      } else {
        nonRecurringEvents.push(event);
      }
    });

    recurringEvents = sortEvents(recurringEvents, 'scheduledStartTime ASC');
    nonRecurringEvents = sortEvents(
      nonRecurringEvents,
      'scheduledStartTime ASC'
    );

    const recurringEventsMap: Record<string, GatheringType[]> = _.groupBy(
      recurringEvents,
      'uuid'
    );

    recurringEvents = Object.values(recurringEventsMap).map((event) => {
      return {
        ...event[0],
        upcomingEvents: event
      };
    });

    return {
      recurringEvents,
      nonRecurringEvents
    };
  }, [data]);

  const columns = React.useMemo(
    () =>
      [
        {
          Header: 'GATHERING NAME',
          accessor: (row: GatheringType) => {
            const startTime = moment(row.scheduledStartTime).tz(
              row.buildingTimezone
            );
            const endTime = row.scheduledEndTime
              ? moment(row.scheduledEndTime).tz(row.buildingTimezone)
              : moment(row.scheduledStartTime)
                  .tz(row.buildingTimezone)
                  .add(1, 'hour');

            const momentNow = moment().tz(row.buildingTimezone);

            const isGatheringLive =
              momentNow.isAfter(startTime) && momentNow.isBefore(endTime);

            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <TextDelimeter
                  textElement={row.title}
                  maxDelimiter={30}
                  moreMessage='See more'
                  lessMessage='See less'
                  contentStyle={{
                    fontSize: '18px',
                    color: 'rgb(39, 33, 99)',
                    fontWeight: 500
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '8px',
                    fontWeight: 500,
                    fontSize: '14px'
                  }}
                >
                  <div style={{ marginRight: '4px' }}>CREATOR:</div>
                  <div style={{ color: 'rgba(39, 33, 99, 0.5)' }}>
                    {row.creator.displayName}
                  </div>
                  {isGatheringLive && (
                    <div className='margin-left-8'>
                      <LiveFlag />
                    </div>
                  )}
                </div>
              </div>
            );
          }
        },
        {
          Header: 'GROUP',
          accessor: (row: GatheringType) => {
            return (
              <TextDelimeter
                textElement={row.groupName}
                maxDelimiter={20}
                moreMessage='See more'
                lessMessage='See less'
                contentStyle={{
                  fontSize: '18px',
                  color: 'rgb(39, 33, 99)',
                  fontWeight: 500
                }}
              />
            );
          }
        },
        {
          Header: 'START DATE & TIME',
          accessor: 'meta.startTime',
          Cell: (cell: CellProps<GatheringType>) => (
            <div>
              {getDisplayDatetime(
                cell.row.original.startTime,
                cell.row.original.buildingTimezone,
                'dddd, DD MMM YYYY, hh:mm A'
              )}
            </div>
          )
        },
        {
          Header: 'EVENT DURATION',
          accessor: 'meta.endTime',
          Cell: (cell: CellProps<GatheringType>) => (
            <div>
              {getEventDuration(
                cell.row.original.startTime,
                cell.row.original.endTime
              )}
            </div>
          )
        },
        {
          Header: 'RSVPs',
          accessor: (row: GatheringType) => {
            return row.rsvps || '0';
          }
        }
      ] as Column<GatheringType>[],
    [history, buildingId]
  );

  const recurrentGatheringTableColumns = React.useMemo(
    () =>
      [
        {
          Header: 'GATHERING NAME',
          accessor: (row: GatheringType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
              >
                <div className='flex-row align-center justify-start'>
                  <div>{row.title} </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '8px',
                    fontWeight: 500,
                    fontSize: '14px'
                  }}
                >
                  <div style={{ marginRight: '4px' }}>CREATOR:</div>
                  <div style={{ color: 'rgba(39, 33, 99, 0.5)' }}>
                    {row.creator.displayName}
                  </div>
                </div>
              </div>
            );
          }
        },
        {
          Header: 'GROUP',
          accessor: (row: GatheringType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {row.groupName}
              </div>
            );
          }
        },
        {
          Header: 'RECURRING FREQUENCY',
          accessor: (row: GatheringType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {row.recurringTypeName}
              </div>
            );
          }
        },
        {
          Header: 'START DATE',
          accessor: 'meta.startTime',
          Cell: (cell: CellProps<GatheringType>) => (
            <div>
              {getDisplayDatetimeWithDefaultDateFormat(
                cell.row.original.startTime,
                cell.row.original.buildingTimezone
              )}
            </div>
          )
        },
        {
          Header: 'NEXT EVENT',
          Cell: (cell: CellProps<GatheringType>) => (
            <div>
              {getDisplayDatetimeWithDefaultDateFormat(
                cell.row.original.upcomingEvents[0].scheduledStartTime!,
                cell.row.original.buildingTimezone
              )}
            </div>
          )
        },
        {
          Header: 'SCHEDULED START TIME',
          Cell: (cell: CellProps<GatheringType>) => (
            <div>
              {getDisplayDatetime(
                cell.row.original.startTime,
                cell.row.original.buildingTimezone,
                'hh:mm A'
              )}
            </div>
          )
        },
        {
          Header: 'EVENT DURATION',
          accessor: 'meta.endTime',
          Cell: (cell: CellProps<GatheringType>) => (
            <div>
              {getEventDuration(
                cell.row.original.startTime,
                cell.row.original.endTime
              )}
            </div>
          )
        },
        {
          Header: 'RECURRENT END DATE',
          Cell: (cell: CellProps<GatheringType>) => (
            <div>
              {getDisplayDatetimeWithDefaultDateFormat(
                convertTimeFromUtcToBuildingTimeZone(
                  cell.row.original.recurrentEndDate!,
                  cell.row.original.buildingTimezone
                ),
                cell.row.original.buildingTimezone
              )}
            </div>
          )
        }
      ] as Column<GatheringType>[],
    [history, buildingId]
  );

  const getPastGatheringsColumns = () => {
    let pastGatheringsColumns: Column<GatheringType>[] = [];
    if (gatheringType === Gathering.PAST) {
      pastGatheringsColumns = [
        {
          Header: 'FEEDBACK RESPONSES',
          accessor: (row: GatheringType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {row.numOfFeedback}
              </div>
            );
          }
        },
        {
          Header: 'AVG RATING',
          accessor: (row: GatheringType) => {
            return (
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                <div className={styles.ratingContainer}>
                  <div style={{ marginLeft: '4px' }}>
                    {formatAverageRating(row.averageRating)}
                  </div>
                  {row.averageRating > 0 && (
                    <div className={styles.starsContainer}>
                      {[...Array(3)].map((item, index) => {
                        const rating = index + 1;
                        return Math.round(row.averageRating) >= rating ? (
                          <StarOn className={styles.star} />
                        ) : (
                          <StarOff className={styles.star} />
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            );
          }
        }
      ];
    }
    return pastGatheringsColumns;
  };
  const getActionColumns = () => {
    let actionColumns: Column<GatheringType>[] = [];
    actionColumns = [
      {
        id: 'duplicate',
        Cell: (cell: CellProps<GatheringType>) => (
          <Link
            to={`/building/${cell.row.original?.buildingId}/gathering/${cell.row.original?.uuid}/duplicate`}
            style={{ textDecoration: 'none' }}
          >
            <div
              style={buttonStyle as React.CSSProperties}
              className={`pointer`}
            >
              DUPLICATE
            </div>
          </Link>
        )
      },
      {
        id: 'download',
        accessor: (row: GatheringType) => {
          return (
            <Link
              to={`/building/${buildingId}/${row.uuid}/rsvp`}
              style={{ textDecoration: 'none' }}
            >
              <div
                style={buttonStyle as React.CSSProperties}
                className={`pointer`}
              >
                VIEW RSVPS
              </div>
            </Link>
          );
        }
      }
    ];

    return actionColumns;
  };

  const getExtraColumns = () => {
    let extraColumns: Column<GatheringType>[] = [];
    if (gatheringType !== Gathering.PAST) {
      extraColumns = [
        {
          id: 'more',
          Cell: (cell: CellProps<GatheringType>) =>
            gatheringType !== Gathering.PAST && (
              <MoreActionsGatherings
                gathering={cell.row.original}
                getGatherings={props.getGatherings}
              />
            )
        }
      ];
    }

    if (gatheringType === Gathering.PAST) {
      extraColumns = [
        ...extraColumns,
        {
          id: 'gatheringFeedback',
          Cell: (cell: CellProps<GatheringType>) => (
            <Link
              to={`/building/${buildingId}/${cell.row.original.uuid}/feedback`}
              style={{ textDecoration: 'none' }}
            >
              <div
                style={buttonStyle as React.CSSProperties}
                className={`pointer`}
              >
                EVENT FEEDBACK
              </div>
            </Link>
          )
        }
      ];
    }
    return extraColumns;
  };

  return (
    <div>
      <div className='margin-top-24 margin-bottom-16'>
        <h4>One-Time Gatherings</h4>
        <CobuTableAdvanced
          columns={[
            ...columns,
            ...getPastGatheringsColumns(),
            ...getActionColumns(),
            ...getExtraColumns()
          ]}
          noData={{ label: 'No non-recurring events found' }}
          data={nonRecurringEvents}
          sort={{
            id: 'meta.startTime',
            descending: gatheringType === Gathering.PAST
          }}
        />
      </div>
      <div className='margin-top-24 margin-bottom-8'>
        <h4>Recurring Gatherings</h4>
        <CobuTableAdvanced
          columns={[
            ...recurrentGatheringTableColumns,
            ...getPastGatheringsColumns(),
            ...getActionColumns(),
            ...getExtraColumns()
          ]}
          noData={{ label: 'No recurring events found' }}
          data={recurringEvents}
          sort={{
            id: 'meta.startTime',
            descending: gatheringType === Gathering.PAST
          }}
        />
      </div>
    </div>
  );
};

export default GatheringsTable;
