import { IFormErrors } from './interfaces';

export const CobuFormErrors: IFormErrors = {
  emailPatternError: 'Oops! Please enter a valid email',
  noEditDoneInitimaton: 'Oops! No values have been modified for edit',
  nameMaxLengthError: 'Oops! We can only accept 25 characters in this section.',
  namePatternError: 'Oops! Please enter a valid name',
  phonePatternError: 'Oops! Please enter a valid phone',
  uriPatternError: 'Oops! Please enter a valid url',
  passwordMinLengthError:
    'Oops! Please enter more than 5 characters for password',
  requiredError: 'This field is required'
};

const CobuConstants: Record<string, any> = {
  empty: '',
  general: {
    more: 'MORE',
    less: 'LESS'
  },
  residentManagement: {
    actions: 'Actions',
    delete: 'Delete',
    deactivate: 'Deactivate',
    sendWelcomeMail: 'Send Welcome Email',
    sendReviewInvitationWithSms: 'Send Review Invitation (with SMS)',
    sendReviewInvitationWithoutSms: 'Send Review Invitation (No SMS)',
    removeFromReviewOutreach: 'Remove from Review Outreach',
    residentsIn: (building: string) => `${building}`,
    activeUsers: 'Active Residents',
    deactivatedUsers: 'Deactivated Residents',
    noActiveUsers: 'No Active Residents',
    noDeactivatedUsers: 'No Deactivated Residents',
    selectAction: 'Select Action',
    createUser: 'Create Resident',
    createresidentsIn: (building: string) => `Create Resident in ${building}`,
    createActionTitle: 'Create',
    activate: 'Activate',

    nilSelectionError: 'Please select atleast 1 resident',
    createResidentLoader: 'Creating the resident...',
    deactivateLoader: 'Deactivating the resident...',
    refreshData: 'Refreshing Data...',
    deactivateMultipleLoader: (count: number) =>
      `Deactivating ${count} residents...`,
    activateLoader: 'Activating the resident...',
    activateMultipleLoader: (count: number) =>
      `Activating ${count} residents...`,
    welcomeMailLoader: 'Sending welcome mail to the resident...',
    reviewInvitationLoader: 'Adding Review Invitation Sent...',
    welcomeMailMultipleLoader: (count: number) =>
      `Sending welcome mail to ${count} residents...`,
    userCreateSuccess: 'Resident created successfully.',
    deactivateSuccess: 'Successfully deactivated the resident.',
    deactivateMultipleSuccess: (count: number) =>
      `Successfully deactivated ${count} residents.`,
    activateSuccess: 'Successfully activated the resident.',
    activateMultipleSuccess: (count: number) =>
      `Successfully activated ${count} residents.`,
    welcomeMailSuccess: 'Welcome mail sent to resident.',
    welcomeMailMultipleSuccess: (count: number) =>
      `Welcome mail sent to ${count} residents.`,
    reviewInvitationSuccess: 'Review Invitation Sent Successfully...'
  },
  createResident: {
    firstName: 'First Name',
    lastName: 'Last Name',
    displayName: 'Display Name',
    email: 'Email',
    phone: 'Phone',
    roomNumber: 'Room Number',
    password: 'Password',
    emailPatternError: 'Oops! Please enter a valid email',
    noEditDoneInitimaton: 'Oops! No values have been modified for edit',
    nameMaxLengthError:
      'Oops! We can only accept 25 characters in this section.',
    namePatternError: 'Oops! Please enter a valid name',
    phonePatternError: 'Oops! Please enter a valid phone',
    uriPatternError: 'Oops! Please enter a valid url',
    passwordMinLengthError:
      'Oops! Please enter more than 5 characters for password',
    tnc: 'Terms and Condition',
    acceptTnc: 'Accept on their behalf',
    doNotDeactivate: 'Do not deactivate',
    cancel: 'Cancel',
    showFullName: 'Show Full Name'
  },
  residentProfile: {
    heading: 'Resident Profile',
    phoneNumber: 'Phone number:',
    unit: 'Unit:',
    profile: 'Profile',
    editUser: 'Edit User',
    removeProfilePic: 'Remove Profile Picture',
    actions: 'Actions',
    residentSince: 'Resident Since:',
    groups: 'Groups',
    likesHelpingWith: 'Likes Helping With:',
    needsHelpWith: 'Needs Help With:',
    bio: 'Bio:',
    notificationSettings: 'NOTIFICATION SETTINGS',
    noComments: 'No Comments found',
    noEvents: 'No Events found',
    noNotifs: 'No Notifications found',

    refreshData: 'Refreshing Resident Data...',
    updateResidentDataLoader: 'Updating Resident Data...',
    updateResidentDataSuccess: 'Resident Data has been updated.',
    removeProfilePicLoader: 'Removing Profile Pic...',
    removeProfilePicSuccess: 'Profile pic is removed successfully.',
    updateNotificationSettingLoader: 'Updating Notification Settings...',
    updateNotificationSettingSuccess:
      'Notification Settings Updated Successfully.'
  },
  createBuilding: {
    buildingName: 'Building Name',
    displayShortName: 'Display Short Name',
    timeZone: 'Time Zone',
    vcm: 'VCM',
    buildingImage: 'Building Image',
    residentPortalLink: 'Resident Portal Link',
    reviewUrl: 'Review URL',
    contactInfoUrl: 'Contact Info URL',
    pollsLink: 'Polls link',
    rafflesLink: 'Raffles link',
    reportEmails: 'Report Emails',
    oneRecipientPerLine: 'One Recipient per line',
    onBoarding: 'Onboarding',
    requiredError: 'This field is required',
    tnc: 'Terms and Condition',
    acceptTnc: 'Accept on their behalf',
    cancel: 'Cancel',
    showFullName: 'Show Full Name',
    includeOnboardingRaffle: 'Include Onboarding Raffle',
    enableSkipDuringOnboarding: 'Enable Skip button during onboarding',
    raffleDescription: 'Raffle Description',
    endDateOfRaffle: 'End date of raffle',
    createActionTitle: 'Create',
    editActionTitle: 'Edit',
    propertyName: 'Property Name',
    buildingLogo: 'Building Logo'
  },
  pinnedPostManagement: {
    title: 'Pinned Posts',
    noPinnedPosts: 'No Pinned Posts',
    fetchingPinnedPosts: 'Fetching Pinned Posts...',
    unPinningPost: 'Unpinning Pinned Post...',
    unpinPostSuccess: 'The Post has been unpinned.',
    createPost: 'Create Post',
    removePin: 'REMOVE PIN'
  },
  eventManagement: {
    title: 'Upcoming Events',
    noPinnedPosts: 'No Pinned Posts',
    fetchingUpcomingEvents: 'Fetching Upcoming Events...',
    unPinningPost: 'Unpinning Pinned Post...',
    unpinPostSuccess: 'The Post has been unpinned.',
    save: 'Save',
    removePin: 'REMOVE PIN'
  },
  scheduleComment: {
    editCommentTitle: 'Edit Scheduled Comment',
    createCommentTitle: 'Create Scheduled Comment',
    buildingLabel: 'Building',
    selectBuildingPlaceholder: 'Select a building',
    groupLabel: 'Group',
    selectGroupPlaceholder: 'Select a group',
    commentContentsLabel: 'Comment Contents',
    creatorEmailLabel: "Creator's Email",
    postNowLabel: 'Post Now',
    postTimeLabel: 'Date & Time of Posting',
    cancelButton: 'Cancel',
    submitButton: 'Submit',
    updateButton: 'Update Comment',
    buildingNotSelectedWarning: 'Please select a building.',
    groupNotSelectedWarning: 'Please select a group.'
  },
  errors: {
    requiredField: 'This field is required.',
    invalidEmail: 'Invalid email address.'
  },
  activeSurveys: {
    title: 'Active Surveys',
    noActiveSurveys: 'No Active Surveys',
    fetchingActiveSurveys: 'Fetching Active Surveys...',
    refreshingActiveSurveys: 'Refreshing Active Surveys...',
    deactivatingSurvey: 'Deactivating Survey...',
    deactivateSurveySuccess: 'The Survey has been deactivated.',
    createSurvey: 'Create Survey',
    deactivateSurvey: 'DEACTIVATE SURVEY',
    viewSurvey: 'view survey',
    createSurveyLoading: 'Creating Survey...',
    createSurveySuccess: 'Survey created successfully.'
  },
  activeTooltips: {
    title: 'Active Tooltips',
    noActiveSurveys: 'No Active Tooltips',
    fetchingActiveSurveys: 'Fetching Active Tooltips...',
    refreshingActiveSurveys: 'Refreshing Active Tooltips...',
    deactivatingSurvey: 'Deactivating Tooltip...',
    deactivateSurveySuccess: 'The Tooltip has been deactivated.',
    createTooltip: 'Create Tooltip',
    deactivateSurvey: 'DEACTIVATE TOOLTIP',
    editToolTip: 'EDIT TOOLTIP',
    viewSurvey: 'view tooltip',
    createSurveyLoading: 'Creating Tooltip...',
    createSurveySuccess: 'Tooltip created successfully.',
    editToolTipLoading: 'Editing Tooltip...',
    ceditToolTipSuccess: 'Tooltip edited successfully.'
  },
  createSurvey: {
    title: 'Title',
    description: 'Description',
    surveyUrl: 'Survey URL',
    callToAction: 'Call to Action',
    buildingNotSelectedWarning: 'Please Select a Building',
    buildingTitle: 'Building',
    buildingPlaceholder: 'Select building...'
  },
  createTooltip: {
    title: 'Title',
    description: 'Description',
    surveyUrl: 'External Link',
    callToAction: 'Call to Action',
    buildingNotSelectedWarning: 'Please Select a Building',
    buildingTitle: 'Building',
    linkLocation: 'Link Location',
    selectLinkLocation: 'Select Link type...',
    buildingPlaceholder: 'Select building...'
  },
  googleReview: {
    fetchGoogleSummary: 'Fetching Unassigned Google Reviews',
    viewReview: 'View Review',
    viewGoogleProfile: 'View Google Profile',
    attributeReview: 'ATTRIBUTE REVIEW',
    googleReviewTitle: (building: string) =>
      `Unassigned Google Reviews of ${building}`,
    noReviews: 'No Reviews to attribute',
    googleSummaryReviewTitle: 'Unassigned Google Reviews',
    noReviewsSummary: 'No Reviews to attribute in any building',
    refreshData: 'Refreshing Reviews Data...',
    reviewModalitle: 'Attribute Review',
    selectUser: 'Select User...',
    notCobu: 'NOT COBU',
    attribute: 'COBU',
    notCobuReview: `Not Cobu Review AND can't find User`,
    reviewNotes: 'REVIEW NOTES',
    reviewNotesPlaceholder: 'Add your review notes if any',
    attributeSuccess: 'Google review has been attributed successfully',
    attributingReviewforUser: (user: string) =>
      `Attributing Google Review to ${user}`,
    attributingReview: 'Attributing Google Review',
    userNotSelectedWarning: 'Please select a user from the list',
    modeNotSelectedWarning: 'Please select who receives credit for the review',
    select: 'Select',
    deselect: 'Deselect',
    selectedUser: 'WHO WROTE THE REVIEW',
    credit: 'WHO RECEIVES CREDIT FOT THE REVIEW',
    cobuUserNotSelected:
      'A COBU USER HAS NOT BEEN SELECTED. PLEASE SELECT A USER TO ATTRIBUTE THIS REVIEW TO.',
    reviewedUser: 'Reviewed User',
    noresidents: 'No Residents',
    assignReview: 'ASSIGN REVIEWS',
    assign: 'ASSIGN',
    backtoList: 'Back to List',
    assignReviewTitle: 'Assign Review',
    localGuide: 'Local Guide:',
    likes: 'Likes:',
    dateFound: 'Date Found:',
    cancel: 'Cancel',
    save: 'Save'
  },
  editGoogleReviewSettings: {
    googleReviewSettingsUpdateSuccess:
      'Google Review Settings Updated Successfully',
    googleReviewSettingsUpdateFailed:
      'Google Review Settings Updation Failed. Please Try Again',
    minSentimentScoreLabel: 'Min Sentiment Score',
    hourOfDayLabel: 'Time of Day to Send Message - based on property time zone',
    recentActivityThresholdLabel: 'Recent Activity Threshold',
    oldActivityMonthThresholdLabel: 'Old Activity Month Threshold',
    activeUserDayThresholdLabel: 'Active User Day Threshold',
    reviewQuotaDivisorLabel: 'Review Quota Divisor',
    maxReviewsPerDayLabel: 'Maximum Reviews Per Day',
    invitationSentWithinDaysLabel: 'Inviation Sent Within Days',
    eligibleResidentActivityDaysLabel: 'Eligible Resident Activity days',
    eligibleResidentCreatedWithinDaysLabel:
      'Eligible Resident Created within Days',
    eligibleGroupCommentMonthsLabel: 'Eligible Group Comment Months',
    allowedNegativeCommentsLabel: 'Allowed Negative Comments',
    recentCommentScoreCalculationMultiplierLabel:
      'Recent Comment Score Calculation Multiplier',
    oldCommentScoreCalculationMultiplierLabel:
      'Old Comment Score Calculation Multiplier',
    recentGreatCommentScoreCalculationMultiplierLabel:
      'Recent Great Comment Score Calculation Multiplier',
    oldGreatCommentScoreCalculationMultiplierLabel:
      'Old Great Comment Score Calculation Multiplier',
    recentRSVPsScoreCalculationMultiplierLabel:
      'Recent RSVPs Score Calculation Multiplier',
    oldRSVPsScoreCalculationMultiplierLabel:
      'Old RSVPs Score Calculation Multiplier',
    numberPatternError: 'Please enter a valid number',
    buttonText: {
      editGoogleReviewSettings: 'Edit Google Review Settings',
      editActionTitle: 'Edit',
      dismiss: 'Dismiss'
    },
    modalTitles: {
      editGoogleReviewSettings: 'Edit Google Review Settings',
      results: 'Results'
    },
    reviewResultsPlaceHolder: 'No Users Targeted For Given Settings'
  },
  deeplink: {
    title: (building: string) => `Deeplinks for ${building}`,
    createDeeplink: 'Create Deeplink',
    deactivatelink: 'Deactivate Deeplink',
    noActiveDeeplinks: 'No Active Deeplinks',
    fetchingActiveDeeplinks: 'Fetching Active Deeplinks...',
    refreshingActiveDeeplinks: 'Refreshing Active Deeplinks...',
    deactivatingDeeplink: 'Deactivating Deeplink...',
    deactivateDeeplinkSuccess: 'The Deeplink has been deactivated.',
    createDeeplinkLoading: 'Creating Deeplink...',
    createDeeplinkSuccess: 'Deeplink created successfully.'
  },
  activeQueues: {
    title: 'Process Queues',
    showDetails: 'Show Details',
    noActiveQueues: 'No active queues available.',
    refreshingActiveQueues: 'Refreshing active queues...',
    fetchingActiveQueues: 'Fetching active queues...',
    deactivateQueue: 'Deactivate Queue',
    deactivateQueueSuccess: 'Queue deactivated successfully!',
    deactivatingQueue: 'Deactivating queue...',
    queueDetails: 'Queue Details'
  },
  queueManagement: {
    title: 'Job Status',
    fetchingJobs: 'Fetching jobs...',
    retryingJob: 'Retrying job...',
    retryJob: 'Retry Job',
    retryAllJob: 'Retry Failed Jobs',
    retryJobSuccess: 'Job retried successfully!',
    retryJobError: 'Error retrying job.',
    noJobs: 'No jobs available.'
  },
  reviewTableHeaders: {
    name: 'Name',
    recentComments: 'Recent Comments',
    oldComments: 'Old Comments',
    recentGreatComments: 'Recent Great Comments',
    oldGreatComments: 'Old Great Comments',
    recentRsvps: 'Recent RSVPS',
    oldRsvps: 'Old RSVPS',
    residentScore: 'Resident Score',
    negativeComments: 'Negative Comments',
    totalScore: 'Total Score',
    mode: 'Mode'
  },
  buildingReports: {
    title: 'Building Reports'
  },
  urls: {
    intercomUrl: (id: string) =>
      `https://app.intercom.com/apps/eozgt9z1/users/show?user_id=${id}`,
    oneSignalUrl: (id: string) =>
      `https://dashboard.onesignal.com/apps/d892b9a7-c6e1-4ba5-9299-73d485090065/subscriptions/${id}`
  },
  navUrls: {
    residentProfileLink: (buildingId: string, residentId: string) =>
      `/building/${buildingId}/residents/${residentId}/profile`,
    buildingDashboardLink: (buildingId: string) => `/building/${buildingId}`,
    assetGoogleReviewLink: (
      ownerGroupId: string,
      ownerName: string,
      isOwnerGroup: boolean
    ) => {
      const encodedOwnerName = encodeURIComponent(ownerName);
      return `/building/assetOverview?ownerGroupName=${encodedOwnerName}&ownerGroupId=${ownerGroupId}&isOwnerGroup=${isOwnerGroup}`;
    }
  }
};

export default CobuConstants;
