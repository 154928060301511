import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import {  fetchGroups } from "~/api/groups"
import { createScheduledComments, deleteScheduledComments, editScheduledComments, getScheduledGroupEventComments } from "~/api/scheduledcomments"
import { CreateCommentBody, Group } from "~/types/scheduledComment"



export default (building: string) => {

    const [scheduledComments, setScheduledComments] = useState([])
    const [groups, setGroups] = useState<Group[]>([])
    const [loadingConfig, setLoadingConfig] = useState<{ flag: boolean, comment?: string }>({
        flag: false,
        comment: ''
    })

    useEffect(() => {
        getScheduledComments()
        getGroups()
    }, [])

    const getScheduledComments = async (refresh = false) => {
        setLoadingConfig({
            flag: true, comment: refresh ? 'Refreshing Scheduled Comments'
                : 'Fetching Scheduled Comments'
        })
        try {
            let commentsResp = await getScheduledGroupEventComments(building)
            setScheduledComments(commentsResp.data)
            setLoadingConfig({ flag: false })
        } catch (error) {
            setLoadingConfig({ flag: false })
            console.log(error)
        }
    }

    const getGroups = async () => {
        try {
            let groupResp = await fetchGroups([building])
            setGroups([{ uuid: 'null', name: 'Building Chat' },...groupResp.data])
        } catch (error) {
            console.log(error)
        }
    }

    const createScheduledComment = async (body: CreateCommentBody) => {
        setLoadingConfig({ flag: true, comment: 'Creating Scheduled Comment...'})
        try {
            let groupResp = await createScheduledComments(body)
            getScheduledComments(true)
        } catch (error) {
            setLoadingConfig({ flag: false })
            console.log(error)
        }
    }


    const editScheduledComment = async (body: CreateCommentBody) => {
        setLoadingConfig({ flag: true, comment: 'Editing Scheduled Comment...'})
        try {
            let groupResp = await editScheduledComments(body)
            getScheduledComments(true)
        } catch (error) {
            setLoadingConfig({ flag: false })
            console.log(error)
        }
    }


    const deleteScheduledComment = async (uuid: string) => {
        setLoadingConfig({ flag: true, comment: 'Deleting Scheduled Comment...'})
        try {
            let groupResp = await deleteScheduledComments(uuid)
            getScheduledComments(true)
        } catch (error) {
            setLoadingConfig({ flag: false })
            console.log(error)
        }
    }

    return {
        scheduledComments,
        groups,
        loadingConfig,
        editScheduledComment,
        deleteScheduledComment,
        createScheduledComment
    }
}