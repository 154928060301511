import { TenantInfo } from '~/containers/RentRoll/TenantRentRollTable';
import { emperor } from '~/services';
import { BaseEmperorResponse } from '~/types/common';
import { CreateResidentBodyType } from '~/types/residentManagement';

export type SendReviewInvitationApiPayload = {
  user: string;
  sendInvitationSms?: boolean;
};

export const getResidents = async (buildingId: string) => {
  return await emperor.get(`v2/building/${buildingId}/users`);
};

export const fetchResidentsMinData = async (buildingId: string) => {
  return await emperor.get(`/building/${buildingId}/attribution-users`);
};

export const createResident = async (residentBody: CreateResidentBodyType) => {
  return await emperor.post(
    `user/verified/${residentBody.building}`,
    residentBody
  );
};

export const deactivateResident = async (residentId: string) => {
  return await emperor.post(`/user/${residentId}/deactivate`);
};

export const deactivateMultipleResidents = async (residentIds: string[]) => {
  return await emperor.post(`/user/deactivate-residents`, {
    users: JSON.stringify(residentIds)
  });
};

export const removeFromGoogleReviewOutreach = async (residentId: string) => {
  return await emperor.post(`/user/remove-from-review-outreach`, {
    userId: residentId
  });
};

export const activateResident = async (residentId: string) => {
  return await emperor.post(`/user/${residentId}/activate`);
};


export const activateMultipleResidents = async (residentIds: string[]) => {
  return await emperor.post(`/user/activate-residents`, {
    users: JSON.stringify(residentIds)
  });
};



export const sendWelcomeMail = async (residentIds: string[]) => {
  return await emperor.post(`/user/send-welcome-mail`, {
    users: JSON.stringify(residentIds)
  });
};

export const sendReviewInvitation = async (
  payload: SendReviewInvitationApiPayload
): Promise<BaseEmperorResponse<any>> => {
  return emperor.post(`/invitation`, payload);
};

export const getResidentProfile = async (residentId: string) => {
  return await emperor.get(`/user/${residentId}/resident-profile`);
};

export const updateResidentProfile = async (
  residentBody: CreateResidentBodyType
) => {
  return await emperor.patch(`/user/${residentBody.id}`, residentBody);
};

export const removeProfilePic = async (residentId: string) => {
  return await emperor.patch(`/user/${residentId}/remove-profile-pic`);
};

export const updateNotifcationSettings = async (
  residentId: string,
  uuids: string[]
) => {
  return await emperor.patch(
    `/user/${residentId}/update-notification-settings`,
    {
      uuids: JSON.stringify(uuids)
    }
  );
};

export const updatePassword = async (userId?: string, password?: string) => {
  return await emperor.patch('/user/update-password', { password, userId });
};

export const uploadAndParseCsvForRentRoll = async (payload: FormData) => {
  return await emperor.post('/resident-management/upload-rent-roll', payload);
};

export const bulkAddTenants = async (payload: {
  building: string;
  tenants: TenantInfo[];
}) => {
  return await emperor.post(
    '/resident-management/bulk-insert-tenants',
    payload
  );
};

export const bulkMoveOutTenants = async (payload: {
  building: string;
  moveOuts: TenantInfo[];
}) => {
  return await emperor.post(
    '/resident-management/bulk-moveout-tenants',
    payload
  );
};
