import React, { CSSProperties } from 'react';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as ImagePlaceholder } from '~/utils/images/image-placeholder.svg';
import Spinner from '../Spinner/Spinner';
import classes from './ImageDropzone.module.css';
import { ReactComponent as FileIcon } from '~/utils/images/file.svg';

type ImageDropzoneProps = {
  handleDrop: (acceptedFiles: File[]) => void;
  image?: File | string;
  spinner?: boolean;
  selectedImgStyles?: CSSProperties;
  acceptedFiles?: string;
};
const ImageDropzone = (props: ImageDropzoneProps) => {
  const {
    handleDrop,
    image,
    spinner,
    selectedImgStyles,
    acceptedFiles = 'image/jpeg, image/png'
  } = props;

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedFiles,
    maxSize: 9961472,
    maxFiles: 1,
    onDropAccepted: handleDrop,
    disabled: spinner
  });

  const isImageDropZone = acceptedFiles.includes('image');

  return (
    <div {...getRootProps()} className='dropzone'>
      <input {...getInputProps()} />
      <div className={classes.dropzoneWrapper}>
        {spinner && (
          <div>
            <Spinner /> 
          </div>
        )}
        {!image && !spinner && <ImagePlaceholder />}
        {image && !spinner && (
          <>
            {isImageDropZone ? (
              <img
                style={selectedImgStyles}
                src={
                  typeof image === 'string' ? image : URL.createObjectURL(image)
                }
                alt='preview'
                className={classes.preview}
              />
            ) : (
              <FileIcon width={100} height={100} />
            )}
          </>
        )}
        <p className={classes.dropImageText}>Drop your image here or browse.</p>
      </div>
    </div>
  );
};

export default ImageDropzone;
