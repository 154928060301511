import { emperor } from '~/services';
import { CreateToolTipBody, Tooltip } from '~/types/tooltip';

export const getAllActiveTooltips = async () => {
  return await emperor.get('tooltip');
};

export const deactivateTooltips = async (surveyID: string) => {
  return await emperor.patch(`tooltip/deactivate`, { uuid: surveyID });
};

export const createBuildingTooltip = async (survey: CreateToolTipBody) => {
  return await emperor.post('tooltip', survey);
};

export const editBuildingTooltip = async (tooltip: CreateToolTipBody) => {
  return await emperor.patch('tooltip', tooltip);
};
