import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  fetchBuildingDashboardConfig,
  fetchBuildingDetails,
  getBuildingAddress
} from '~/api/building';
import {
  fetchBuildingEntrataIntegrationDetails,
  fetchBuildingYardiIntegrationDetails
} from '~/api/buildingEntrataIntegration';
import {
  IReferralExternalEmail,
  IReferralKnockEmail,
  getReferralExternalEmails,
  getReferralKnockEmail,
  postCommunityLeadSetting,
  postReferralEntrataIntegration,
  postReferralExternalEmails,
  putReferralKnockEmail,
  postReferralYardiIntegration,
  syncReferralIntercomForBuildingUsers
} from '~/api/referral';
import {
  IReferralConfigSetting,
  IReferralSettingEntrataPMS,
  IReferralSettingYardiPMS
} from '~/containers/Referral/interfaces';
import { IMultiSelectOptionList } from '~/helpers/interfaces';
import { Building, BuildingDashboardConfigResponse } from '~/types/building';
import {
  BuildingEntrataIntegration,
  BuildingYardiIntegration
} from '~/types/buildingEntrataIntegration';

export default (buildingId: string) => {
  const [loader, setLoader] = useState(false);
  const [buildingConfig, setBuildingConfig] =
    useState<BuildingDashboardConfigResponse | null>(null);
  const [buildingData, setBuildingData] = useState<Building | null>(null);
  const [entrataIntegrations, setEntrataIntegrations] = useState<
    BuildingEntrataIntegration[] | null
  >(null);
  const [yardiIntegrations, setYardiIntegrations] = useState<
    BuildingYardiIntegration[] | null
  >(null);
  const [entrataCheckboxOptions, setEntrataCheckboxOptions] = useState<
    IMultiSelectOptionList[] | null
  >(null);
  const [externalEmail, setExternalEmail] =
    useState<IReferralExternalEmail | null>(null);
  const [knockEmail, setKnockEmail] = useState<string | null>(null);

  const fetchBuildingConfig = async () => {
    try {
      setLoader(true);
      const res = await fetchBuildingDashboardConfig(buildingId);
      const buildingRes = await fetchBuildingDetails(buildingId);

      setBuildingData(buildingRes.data);
      setBuildingConfig(res.data);
      setLoader(false);
    } catch (e) {
      setLoader(false);

      toast('Failed to fetch building config');
    }
  };

  const fetchEntrataIntegrations = async () => {
    try {
      setLoader(true);

      const res = await fetchBuildingEntrataIntegrationDetails(buildingId);

      setEntrataIntegrations(res.data);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      toast('Failed to fetch entrata integrations');
    }
  };

  const fetchYardiIntegrations = async () => {
    try {
      setLoader(true);
      const res = await fetchBuildingYardiIntegrationDetails(buildingId);

      setYardiIntegrations(res.data);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      toast('Failed to fetch yardi integrations');
    }
  };

  const fetchCustomEmails = async () => {
    try {
      setLoader(true);

      const res = await getReferralExternalEmails(buildingId);

      setExternalEmail(res.data);
    } catch (e) {
      setLoader(false);
      toast('Failed to fetch custom emails');
    }
  };

  const fetchKnockEmail = async () => {
    try {
      setLoader(true);

      const res = await getReferralKnockEmail(buildingId);

      setKnockEmail(res.data.email);
    } catch (e) {
      setLoader(false);

      if (e.status !== 404) {
        toast('Failed to fetch custom emails');
      }
    }
  };

  useEffect(() => {
    Promise.all([
      fetchEntrataIntegrations(),
      fetchYardiIntegrations(),
      fetchCustomEmails(),
      fetchKnockEmail()
    ]).then(() => {
      fetchBuildingConfig();
    });
  }, []);

  useEffect(() => {
    if (entrataIntegrations && entrataIntegrations.length) {
      const entrataCheckboxOptions = entrataIntegrations?.map(
        (integration) => ({
          value: integration.uuid,
          text: `domain: ${integration.entrata_domain}, propertId: ${integration.entrata_property_id}`,
          isSelected: integration.default
        })
      );

      setEntrataCheckboxOptions(
        entrataCheckboxOptions as IMultiSelectOptionList[]
      );
    }
  }, [entrataIntegrations]);

  const postCommunitySetting = async (
    buldingId: string,
    settings: IReferralConfigSetting
  ) => {
    try {
      setLoader(true);
      await postCommunityLeadSetting(buldingId, settings);

      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const postEntrataPMSSetting = async (
    buldingId: string,
    entrataIntegration: IReferralSettingEntrataPMS
  ) => {
    try {
      setLoader(true);
      await postReferralEntrataIntegration(buldingId, entrataIntegration);

      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const postYardiPMSSetting = async (payload: {
    buildingId: string;
    agentName?: string;
    leadSourceName?: string;
    yardiVersion?: string;
    credentials?: string;
    settings: IReferralSettingYardiPMS;
  }) => {
    try {
      setLoader(true);
      await postReferralYardiIntegration(payload);

      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const postCustomEmails = async (buldingId: string, emailsString: string) => {
    try {
      setLoader(true);
      await postReferralExternalEmails(buldingId, emailsString);

      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const putKnockEmail = async (buldingId: string, email: string) => {
    try {
      setLoader(true);
      await putReferralKnockEmail(buldingId, email);

      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  const showIntercomSync = buildingConfig?.config?.communityLead ?? false;

  const syncReferralIntercomData = async () => {
    try {
      setLoader(true);
      await syncReferralIntercomForBuildingUsers(buildingId);

      setLoader(false);

      toast('Intercom Referral details for all users are synced successfully!');
    } catch (e) {
      setLoader(false);
    }
  };

  return {
    loader,
    postCommunitySetting,
    postEntrataPMSSetting,
    postYardiPMSSetting,
    postCustomEmails,
    putKnockEmail,
    buildingConfig,
    buildingData,
    entrataIntegrations,
    entrataCheckboxOptions,
    setEntrataCheckboxOptions,
    yardiIntegrations,
    externalEmail,
    knockEmail,
    syncReferralIntercomData,
    showIntercomSync
  };
};
