import { emperor } from "~/services";
import { CreateCommentBody } from "~/types/scheduledComment";
import moment from 'moment-timezone';

export const getScheduledGroupEventComments = async (uuid: string) => {
    return await emperor.get(`/scheduledgroupcomment`, {
      params: {
        group: 'null',
        building: uuid,
        limit: 300,
      },
    });
  };

  export const deleteScheduledComments = async (uuid: string) => {
    return await emperor.delete(`/scheduledgroupcomment/${uuid}`,)
  };



export const createScheduledComments = async (values: CreateCommentBody) => {
    const formdata = new FormData();
    formdata.append('building', values.building);
    formdata.append('creatorEmail', values.creatorEmail);
    formdata.append('group', values.group);
    if (values.contents) formdata.append('contents', values.contents.trim());
    if (values.postTime) {
      formdata.append(
        'postTime',
        moment.tz(values.postTime, values.timezone).format()
      );
    }
  
    if (values.image) {
      formdata.append('images', values.image);
    }
  
    return await emperor.post(`/scheduledgroupcomment/create`,formdata);
  };

  export const editScheduledComments = async (values: CreateCommentBody) => {
    const formdata = new FormData();
    formdata.append('uuid', values.uuid ?? '');
    formdata.append('building', values.building);
    formdata.append('creatorEmail', values.creatorEmail);
    formdata.append('group', values.group);
    if (values.contents) formdata.append('contents', values.contents.trim());
    if (values.postTime) {
      formdata.append(
        'postTime',
        moment.tz(values.postTime, values.timezone).format()
      );
    }
  
    if (values.image) {
      formdata.append('images', values.image);
    }
  
    return await emperor.patch(`/scheduledgroupcomment/update`,formdata);
  };


  