import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import CobuConstants from '~/helpers/cobuConstants';
import {
  ResidentInputFieldConfig,
  CreateResidentFormType
} from '~/types/residentManagement';
import MultiSelect from '../MultiSelect/MultiSelect';
import { SelectedOptionType } from '~/types/form';
import { SelectType } from '~/enums/SelectType';
import Spacer from '../Spacer/Spacer';
import { toast } from 'react-toastify';
import { CreateSurveyBody } from '~/types/surveys';
import { useCobuSelector } from '~/hooks/useCobuSelector';
import { CreateToolTipBody, Tooltip } from '~/types/tooltip';
import { externalURLFormConfig } from '~/helpers/formHelpers';

type IProps = {
  createTooltip: (r: CreateToolTipBody) => void;
  updateToolTip: (r: CreateToolTipBody) => void;
  cancel: () => void;
  formConfig: ResidentInputFieldConfig[];
  defaultData: Tooltip | null;
};

const toolTipLinksForDropdown: SelectedOptionType[] = [
  {
    value: 'Referral',
    label: 'Referral Details'
  },
  {
    value: 'Events',
    label: 'Events Page'
  },
  {
    value: 'Groups',
    label: 'Groups Page'
  },
  {
    value: 'Perks',
    label: 'Perks Page'
  },
  {
    value: 'Marketplace',
    label: 'Marketplace'
  },
  {
    value: 'External',
    label: 'External'
  }
];

const CreateTooltipForm = ({
  cancel,
  createTooltip,
  updateToolTip,
  formConfig,
  defaultData
}: IProps) => {
  const buildings = useCobuSelector((state) => state.building.buildings);
  const [multiValue, setMultiValue] = useState<SelectedOptionType[]>(() =>
    defaultData
      ? [{ label: defaultData.building.name, value: defaultData.building.uuid }]
      : []
  );
  const [multiValueType, setMultiValueType] = useState<SelectedOptionType[]>(
    () => {
      if (!defaultData) {
        return [];
      } else {
        const foundToolTipLink = toolTipLinksForDropdown.find(
          (likeKeyValue) => likeKeyValue.value === defaultData.linkType
        );

        return foundToolTipLink ? [foundToolTipLink] : [];
      }
    }
  );

  const { register, handleSubmit, errors } = useForm<any>({
    defaultValues: defaultData
      ? { ...defaultData, building: defaultData.building.uuid }
      : {}
  });

  const onSubmit: SubmitHandler<CreateToolTipBody> = (survey) => {
    if (multiValue.length === 0) {
      toast(CobuConstants.createSurvey.buildingNotSelectedWarning);
      return;
    }
    let building = multiValue[0].value;
    if (multiValueType.length === 0) {
      toast(CobuConstants.createSurvey.buildingNotSelectedWarning);
      return;
    }
    let linkType = multiValueType[0].value;

    if (defaultData) {
      updateToolTip({
        ...survey,
        uuid: defaultData.uuid,
        building,
        linkType
      });

      return;
    }
    createTooltip({
      ...survey,
      building,
      linkType
    });
  };

  const getBuildingsForDropdown = () => {
    const buildingsForDropdown: SelectedOptionType[] = buildings.map(
      (building: any) => {
        return {
          value: building.uuid,
          label: building.name
        };
      }
    );
    return buildingsForDropdown;
  };

  const externalFormField = () => {
    if (multiValueType.length) {
      let linkType = multiValueType[0].value;
      if (linkType === 'External') {
        return generateField(externalURLFormConfig);
      }
    }
    return null;
  };

  const generateField = (fieldConfig: ResidentInputFieldConfig) => {
    let error = errors[`${fieldConfig.id}` as keyof CreateSurveyBody];

    return (
      <div key={fieldConfig.id}>
        <div className='item'>
          <label htmlFor='title'>{fieldConfig.label}</label>
          <input
            type='text'
            id={fieldConfig.id}
            name={fieldConfig.id}
            readOnly={fieldConfig.readOnly}
            ref={register({
              required: true,
              pattern: fieldConfig.pattern,
              maxLength: fieldConfig.maxLength,
              minLength: fieldConfig.minLength
            })}
            className={`${fieldConfig.readOnly && 'nonEditable'} ${
              error && 'border-red'
            }`}
          />
          {error && error.type === 'required' && (
            <span className='errorTitle' role='alert'>
              {CobuConstants.createResident.requiredError}
            </span>
          )}
          {error && fieldConfig.pattern && error.type === 'pattern' && (
            <span className='errorTitle' role='alert'>
              {fieldConfig.patternErrorMsg}
            </span>
          )}
          {error && fieldConfig.maxLengthError && error.type === 'maxLength' && (
            <span className='errorTitle' role='alert'>
              {fieldConfig.maxLengthError}
            </span>
          )}
          {error && fieldConfig.maxLengthError && error.type === 'minLength' && (
            <span className='errorTitle' role='alert'>
              {fieldConfig.minLengthError}
            </span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='gridContainer'>
          <div>
            <label htmlFor='title'>
              {CobuConstants.createTooltip.buildingTitle}
            </label>
            <Spacer height={8} />
            <MultiSelect
              singleSelect
              disabled={!!defaultData}
              options={getBuildingsForDropdown()}
              selectType={SelectType.Form}
              multiValue={multiValue ?? [{ label: '', value: '' }]}
              setMultiValue={setMultiValue}
              placeholder={CobuConstants.createSurvey.buildingPlaceholder}
              cobuPlusBuildings={[]}
            />
          </div>
          <div>
            <label htmlFor='title'>
              {CobuConstants.createTooltip.linkLocation}
            </label>
            <Spacer height={8} />
            <MultiSelect
              singleSelect
              options={toolTipLinksForDropdown}
              selectType={SelectType.Form}
              multiValue={multiValueType ?? [{ label: '', value: '' }]}
              setMultiValue={setMultiValueType}
              placeholder={CobuConstants.createSurvey.selectLinkLocation}
              cobuPlusBuildings={[]}
            />
          </div>
          {formConfig.map((field) => generateField(field))}
          {externalFormField()}
        </div>
        <div className='formButtons'>
          <button className='btn-standard btn-secondary' onClick={cancel}>
            {CobuConstants.createResident.cancel}
          </button>
          <button
            className='btn-standard btn-primary margin-left-16 '
            type='submit'
          >
            {defaultData ? 'Update' : 'Create'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateTooltipForm;
