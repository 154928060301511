import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Toast } from 'react-toastify/dist/components';
import { addImageToBuilding, deleteBuildingImage, getBuildingImages } from '~/api/images';


type UseBuildingImagesResult = {
    images: any[];
    loading: boolean;
    loadingString: string;
    error: string | null;
    addImage: (building: Object) => Promise<void>;
    fetchImages: (building: string) => Promise<void>;
    deleteImage: (building: string, image: string) => Promise<void>;
};


const useBuildingImages = (building: string): UseBuildingImagesResult => {
    const [images, setImages] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [loadingString, setLoadingString] = useState('');
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchImages(building)
    }, [])

    /**
      * Fetch Building Images
      */
    const fetchImages = async (building: string) => {
        setLoadingString('Loading Building Images...')
        setLoading(true);
        setError(null);

        try {
            const response = await getBuildingImages(building)
            setImages(response?.data.data || []);
        } catch (err) {
            setError('Failed to fetch building images.');
            console.error(err);
        } finally {
            setLoading(false);
            setLoadingString('')
        }
    };

    /**
  * Fetch Building Images
  */
    const addImage = async (imageObj: Object) => {
        setLoadingString('Adding Building Image...')
        setLoading(true);
        setError(null);

        try {
            const response = await addImageToBuilding(imageObj)
            setImages(response?.data?.data?.images || []);
            toast('The image has been added successfully')
        } catch (err) {
            setError('Failed to fetch building images.');
            console.error(err);
        } finally {
            setLoading(false);
            setLoadingString('')
        }
    };

    /**
     * Delete Image from Building
     */
    const deleteImage = async (building: string, image: string) => {
        setLoadingString('Removing the building image')
        setLoading(true);
        setError(null);

        try {
            await deleteBuildingImage(building, image)

            if (images) {
                setImages(
                    images.filter((img: string) => img !== image),
                );
                toast('The image has been removed successfully')
            }
        } catch (err) {
            setError('Failed to delete image.');
            console.error(err);
        } finally {
            setLoading(false);
            setLoadingString('')
        }
    };

    return {
        images,
        loading,
        loadingString,
        error,
        addImage,
        fetchImages,
        deleteImage,
    };
};

export default useBuildingImages;