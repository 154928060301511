import React, { useState } from 'react';
import ImageDropzone from '~/components/ImageDropzone/ImageDropzone';
import Spinner from '~/components/Spinner/Spinner';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Spacer from '~/components/Spacer/Spacer';
import useBuildingImages from '~/hooks/building/images/useBuildingImages';
import { useParams } from 'react-router-dom';
import classes from './EditBuildingImages.module.css';

interface UploadedImage {
  id: string;
  file: File;
  url: string;
}

const EditBuildingImages: React.FC = () => {
  const { buildingId } = useParams<{ buildingId: string }>();
  const { images, loading, loadingString, addImage, deleteImage } = useBuildingImages(buildingId);
  const [zoomedImage, setZoomedImage] = useState<string | null>(null);

  // Handle image drop from dropzone
  const handleImageDrop = async (files: File[]) => {
    await addImage({
      image: files[0],
      building: buildingId,
    });
  };

  // Handle image zoom
  const handleImageClick = (image: string) => {
    setZoomedImage(image);
  };

  // Close zoomed image
  const handleCloseZoom = () => {
    setZoomedImage(null);
  };

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <Spinner />
        <h3 style={{ textAlign: 'center' }}>{loadingString}</h3>
      </div>
    );
  }

  return (
    <div className="content-container">
      <Breadcrumbs />
      <Spacer height={20} />
      <h1 className="h1-title">Building Images</h1>
      <Spacer height={20} />

      <ImageDropzone handleDrop={handleImageDrop} acceptedFiles="image/jpeg, image/png" />
      <Spacer height={20} />

      {images.length > 0 && (
        <div className={classes.uploadedImagesContainer}>
          <h3>Uploaded Images</h3>
          <Spacer height={20} />
          <div className={classes.imageGrid}>
            {images.map((image) => (
              <div key={image} className={classes.imageWrapper}>
                <img
                  src={image}
                  alt="Uploaded preview"
                  className={classes.uploadedImage}
                  onClick={() => handleImageClick(image)}
                />
                <span
                  className={classes.removeIcon}
                  onClick={() => deleteImage(buildingId, image)}
                >
                  &times;
                </span>
              </div>
            ))}
          </div>
        </div>
      )}

      {zoomedImage && (
        <div className={classes.zoomedImageOverlay} onClick={handleCloseZoom}>
          <div className={classes.zoomedImageContainer}>
            <img src={zoomedImage} alt="Zoomed preview" className={classes.zoomedImage} />
            <button className={classes.closeButton} onClick={handleCloseZoom}>
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditBuildingImages;